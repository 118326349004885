<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
                    <div class="card">
                        <div class="card-body table-responsive table-head pt-2">
                            <div class="row">
                                <div class="col-md-8 text-left px-0 bor-bot pb-3">
                                    <h1 class="m-0 text-dark pt-2 text-left px-0">FAQ List</h1>
                                </div>
                                <div class="col-md-4 bor-bot pr-0">
                                    <div class="text-right pb-3 pt-1">
                                        <ol class="breadcrumb float-sm-right pr-0">
                                            <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                                            <li class="breadcrumb-item"><Breadcrumbs/></li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                          <custom-alert v-if="displayAlert==true" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
                            <div class="row" v-if="emptyfaq">
                              <div class="col-md-12 text-center adfaqcont">
                                <div>
                                  <img src="/dist/faq-icon.svg"/>
                                  <h3>Currently you don’t have any FAQ</h3>
                                  <a href="/admin/add-faq" class="btn btn darkblubtn btn-outline-primary btn-outline-primary">Add FAQ</a>
                                </div>
                              </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 mt-3 showentries pl-0">
                                </div>
                                <div class="col-md-6 pr-0">
                                <div class="mt-3 mb-3 text-right pr-0 emltmpserchsec">
                                    <div class=" card-text d-flex align-items-center float-right mb-0">
                                        <div>
                                          <a href="/admin/add-faq" class="btn btn darkblubtn btn-outline-primary btn-outline-primary">Add FAQ</a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 px-0 mt-3 faqsearchsec faqseclisttable">
                                    <table class="table table-sm table-bordered table-hover mx-0" style="width:100%;" id="list_table">
                                        <thead class="thead-dark">
                                        <tr>
                                            <th>Section</th>
                                            <th>Question</th>
                                            <th>Priority</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td colspan="6">&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colspan="6">&nbsp;</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                          </div>
                    </div>
                </section>
            </div>
        </div>
      <b-modal size="lg" id="modal-1" ref="my-modal" title="Answer" :modal-class="textareamodal">
        <p class="my-4" v-html="answer"></p>
      </b-modal>
    </section>
</template>

<script>
import $ from 'jquery';
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import axios from "axios";
import Errors from "../../Errors";
import CustomAlert from "../CustomAlert";

  export default {
    name:"FAQ",
    components:{
      CustomAlert
    },
    data() {
      return {
        textareamodal: 'textareacontmodal',
        table:'',
        emptyfaq:false,
        //faqlist:false,
        alertMessage: "",
        displayAlert: false,
        answer:'',
        sections:[]
      }
    },
    methods:{
      deleteRow(id) {
        this.displayAlert = false;
        if(confirm("Are you sure, do you want to delete?")) {
          axios
              .delete(process.env.VUE_APP_URL + "api/faq/list/" + id, {headers: this.adminHeaders})
              .then((response) => {
                //alert("Deleted Successfully");
                this.displayAlert = true;
                this.alertMessage = "Deleted Successfully";
                //this.resetEditModal();
                this.table.draw();
              })
              .catch((error) => {
                (error) => (this.errors = error.response.data);
              });
        }
        return false;
      },
      isdeleteRow(id){
        this.deleteRow(id)
      }
    },
    mounted(){
      // this.emptyfaq=true;
      // this.faqlist=true;
        window.reg=this;
        this.table = $("#list_table").DataTable({
            dom: 'lfrtip',
            "bFilter": true,
            processing: true,
            serverSide: true,
            responsive: true,
            ajax: {
                url: process.env.VUE_APP_URL + "api/faq/list",
                type: 'get',
                headers:this.adminHeaders
            },
            columns: [
                { data: "section_name", name: "section_name"},
                { data: 'question', name: 'question'},
                { data: "priority_order", name: "priority_order"},
                { data: "status_txt", name: "status_txt"},
                { data: "actions", name: "actions", orderable: false, render: (data,type,row,meta) => {
                    let btn = '';
                    btn += `<a class="action-editing viewAnswer" href="javascript:void(0)" title="View Answer" data-answer="${encodeURIComponent(row.answer)}"  ><i class="fas fa-eye"></i></a> `
                    btn += `<a class="action-editing" data-id="${row.id}" href="/admin/edit-faq/${row.id}" ><i class="fas fa-edit"></i></a> `
                    if(row.status==0)
                    btn += `<a class="action-delete faq_trash" data-id="${row.id}" href="javascript:void(0);" ><i class="fas fa-trash"></i></a>`
                    return btn
                  }
                }
            ],
        });
        document.addEventListener("click", function(e){
          if(e.target && e.target.parentNode.className == "action-delete faq_trash"){
            var dataid = e.target.parentNode.getAttribute('data-id');
            window.reg.deleteRow(dataid);
          }
          if(e.target && e.target.parentNode.className == "action-editing viewAnswer"){
            var answer = e.target.parentNode.getAttribute('data-answer');
            window.reg.answer = decodeURIComponent(answer);
            window.reg.$refs['my-modal'].show()
          }
        });
    }
  }
</script>